/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'
import HeroImage from 'components/hero-image'
import styles from './style.module.css'
import useScreenExitDetection from 'hooks/useScreenExitDetection'
import { DIRECTION_VERTICAL } from 'enum/direction'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { IUiResource } from 'interfaces/ui-resource'
import { useFeature } from '@growthbook/growthbook-react'
import { priceElasticityExperimentControlVariationName, priceElasticityExperimentName } from 'constants/growthbook'
import { getFormattedNumberWithCurrency } from 'lib/util/currency'
import { useGrowthBookWrapperContext } from 'context/growthbook-context'
import Loader from 'components/loading/loader'

interface IProps {
    pageSectionHero: IPageSectionHero
    lowestStarterSetPrices: IUiResource
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="text-3.5xl lg:text-6.25xl text-white lg:text-black leading-110% tracking-tight">{children}</div>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="leading-140%">{children}</p>,
}

export const HeroSectionSplitScreen = ({ pageSectionHero, lowestStarterSetPrices }: IProps): React.ReactElement => {
    const { richHeader, richSubHeader, primaryCtaLink, mobileBackgroundImagesCollection, backgroundImagesCollection } =
        pageSectionHero

    const [sectionEl, setSectionEl] = React.useState<HTMLElement>()

    const floatingElRef = React.useRef<HTMLDivElement>()

    const sectionElRef = React.useCallback((node) => {
        if (node !== null) {
            setSectionEl(node)
        }
    }, [])

    const hasHeroSectionExitedViewport = useScreenExitDetection({
        element: sectionEl,
        direction: DIRECTION_VERTICAL.TOP,
    })

    const isMobileScreen = useMobileScreenDetection()

    React.useEffect(() => {
        if (hasHeroSectionExitedViewport === false) {
            document.documentElement.style.setProperty('--fixedToBottomElementHeight', '0px')
        } else {
            if (floatingElRef.current && isMobileScreen) {
                const floatingElHeight = floatingElRef.current?.clientHeight

                document.documentElement.style.setProperty('--fixedToBottomElementHeight', floatingElHeight + 'px')
            }
        }

        return () => {
            document.documentElement.style.setProperty('--fixedToBottomElementHeight', '0px')
        }
    }, [hasHeroSectionExitedViewport, isMobileScreen])

    const { isGrowthbookReady } = useGrowthBookWrapperContext()
    const priceElasticityExperiment = useFeature(priceElasticityExperimentName)
    const assignedPriceVariation = priceElasticityExperiment?.value ?? priceElasticityExperimentControlVariationName

    const lowestStarterSetPricesJSON = JSON.parse((lowestStarterSetPrices?.value as string) ?? '{}')
    const lowestStarterSetPrice = lowestStarterSetPricesJSON[assignedPriceVariation]

    const lowestStarterSetPriceFormatted =
        typeof lowestStarterSetPrice === 'number'
            ? getFormattedNumberWithCurrency(lowestStarterSetPrice, undefined)
            : ''

    return (
        <>
            <section
                ref={sectionElRef}
                className={`grid grid-cols-1 lg:grid-cols-2 grid-rows-1 relative ${styles['hero-section']} ${styles['hero-section--tall']}`}
            >
                {/* content */}
                <div className="p-5 pt-[5.5rem] lg:p-15 lg:pt-28 flex flex-col items-start lg:bg-white relative z-10">
                    {richHeader?.json && (
                        <h2>
                            <ContentfulRichText content={richHeader.json} renderNode={headerRenderNode} />
                        </h2>
                    )}

                    {richSubHeader?.json && (
                        <div className="hidden lg:block mt-12">
                            <ContentfulRichText content={richSubHeader.json} renderNode={subHeaderRenderNode} />
                        </div>
                    )}

                    {/* cta */}
                    {primaryCtaLink?.href && (
                        <Link
                            href={primaryCtaLink.href}
                            className="section--primary-cta button lg:button-primary bg-white lg:bg-black border-white lg:border-current inline-block mt-auto lg:mt-12 w-full lg:w-auto"
                        >
                            {!isGrowthbookReady ? (
                                <Loader secondary={isMobileScreen} />
                            ) : (
                                primaryCtaLink.name?.replace('{price}', lowestStarterSetPriceFormatted)
                            )}
                        </Link>
                    )}
                </div>

                {/* image */}
                <HeroImage
                    className="h-full w-full object-cover object-right-bottom absolute top-0 left-0 lg:static"
                    backgroundImage={backgroundImagesCollection?.items?.[0]}
                    mobileBackgroundImage={mobileBackgroundImagesCollection?.items?.[0]}
                />
            </section>
        </>
    )
}

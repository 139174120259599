/* eslint-disable react/display-name */
import * as React from 'react'
import HeroImage from 'components/hero-image'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import Link from 'next/link'
import { BLOCKS } from '@contentful/rich-text-types'
import HeroVideo from 'components/hero-video'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { IUiResource, IUiResources } from 'interfaces/ui-resource'
import dynamic from 'next/dynamic'
import Loader from 'components/loading/loader'
import { ICommonCopyDict } from 'interfaces/common-copy'
import { heroSectionRenderTypeWhichRendersRxSearchForm } from 'misc-variables'
import { rxHeroCopyTestFeatureName } from 'constants/growthbook'
import { useFeature } from '@growthbook/growthbook-react'
import { useGrowthBookWrapperContext } from 'context/growthbook-context'

interface IProps {
    pageSectionHero: IPageSectionHero
    playMediaLabel: IUiResource
    uiResources?: IUiResources
    commonCopy?: ICommonCopyDict
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="text-5xl lg:text-6.5xl xl:text-7xl font-medium leading-110% tracking-tighter">{children}</div>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xbase lg:text-base xl:text-xl font-medium leading-140%">{children}</p>
    ),
}

const RxSearchForm = dynamic(() => import('components/rx-search-form'), {
    loading: () => <Loader />,
})

export const HeroSectionSplitScreenLargeImage = ({
    pageSectionHero,
    playMediaLabel,
    uiResources,
    commonCopy,
}: IProps): React.ReactElement => {
    const isMobileScreen = useMobileScreenDetection()
    const { isGrowthbookReady } = useGrowthBookWrapperContext()
    const rxHeroCopyTestExp = useFeature(rxHeroCopyTestFeatureName)

    if (!pageSectionHero) {
        return null
    }

    const {
        richHeader,
        richSubHeader,
        primaryCtaLink,
        mobileBackgroundImagesCollection,
        backgroundImagesCollection,
        renderType,
        inputsCollection,
    } = pageSectionHero

    const mobileMedia = mobileBackgroundImagesCollection.items?.[0]
    const desktopMedia = backgroundImagesCollection.items?.[0]

    const hasVideo = mobileMedia?.contentType?.includes('video') || desktopMedia?.contentType?.includes('video')

    const rxSearchInput = inputsCollection.items?.[0]

    const rxHeroCopyContent = rxHeroCopyTestExp.value ?? richHeader.json

    const isRxSearchFormSection = renderType === heroSectionRenderTypeWhichRendersRxSearchForm

    return (
        <>
            {/* deducting 160px from hero height to give some breathing space + move the next
                icons section above the fold */}
            <section
                className={`
                section--v2 mt-0 p-0 grid grid-cols-1 lg:grid-rows-1 relative lg:min-h-[500px] lg:h-[var(--screenHeightDeductingHeader)] max-h-[var(--heroMaxHeight)] z-30
                ${
                    renderType === 'content left, search center, large image right'
                        ? `lg:grid-cols-2 mb-24`
                        : `lg:grid-cols-[1fr,2fr]`
                }
                `}
            >
                {/* image */}
                <div className="lg:col-start-2 lg:col-end-3 lg:pt-5 ">
                    {hasVideo ? (
                        <HeroVideo
                            className="h-full w-full object-cover max-h-[450px] lg:max-h-[none] lg:rounded-l-2lg"
                            video={isMobileScreen ? mobileMedia ?? desktopMedia : desktopMedia ?? mobileMedia}
                            playMediaLabel={playMediaLabel}
                        />
                    ) : (
                        <HeroImage
                            className="h-full w-full object-cover max-h-[450px] lg:max-h-[none] lg:rounded-l-2lg"
                            backgroundImage={desktopMedia}
                            mobileBackgroundImage={mobileMedia}
                        />
                    )}
                </div>

                {/* content */}
                <div
                    className={`px-5 pt-8 lg:pl-15 lg:pr-5 lg:pt-12 2xl:pt-16 flex flex-col items-start sm:items-center lg:items-start relative z-10 lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-2 lg:mr-20 ${
                        isRxSearchFormSection ? 'justify-between' : ''
                    }`}
                >
                    <div>
                        {isGrowthbookReady ? (
                            rxHeroCopyContent && (
                                <h2>
                                    <ContentfulRichText
                                        className="sm:text-center lg:text-left"
                                        content={rxHeroCopyContent}
                                        renderNode={headerRenderNode}
                                    />
                                </h2>
                            )
                        ) : (
                            // Skeleton while Growthbook is loading
                            <div className="animate-pulse gap-y-4 grid">
                                <div className="h-12 bg-gray-medium rounded-lg max-w-xl"></div>
                                <div className="h-12 bg-gray-medium rounded-lg max-w-lg"></div>
                                <div className="h-12 bg-gray-medium rounded-lg max-w-sm"></div>
                            </div>
                        )}

                        {isRxSearchFormSection && (
                            <div>
                                {richSubHeader?.json && (
                                    <ContentfulRichText
                                        className="sm:text-center lg:text-left lg:row-start-1 lg:row-end-2 mt-8"
                                        content={richSubHeader.json}
                                        renderNode={subHeaderRenderNode}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        {richSubHeader?.json && renderType != 'content left, search center, large image right' && (
                            <div className="mt-5">
                                <ContentfulRichText
                                    className="sm:text-center lg:text-left"
                                    content={richSubHeader.json}
                                    renderNode={subHeaderRenderNode}
                                />
                            </div>
                        )}
                        {/* cta */}
                        {primaryCtaLink?.href && (
                            <Link
                                href={primaryCtaLink.href}
                                className="section--primary-cta button button-primary inline-block mt-5 lg:mt-9 w-full lg:block"
                            >
                                {primaryCtaLink.name}
                            </Link>
                        )}
                    </div>

                    {isRxSearchFormSection && (
                        <div className="gap-y-5 xl:gap-y-8 grid grid-cols-1 mt-5 xl:mt-8">
                            <div className="lg:row-start-2 lg:row-end-3">
                                <RxSearchForm
                                    uiResources={uiResources}
                                    commonCopy={commonCopy}
                                    searchInput={rxSearchInput}
                                    showIconsAndCopy={true}
                                    setIsSearchActive={() => {}}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}
